export const pageTurnSounds = [
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-01a.mp3?alt=media&token=d294c468-73c9-454c-9847-ef7d84fb10ed",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-02.mp3?alt=media&token=d988e564-7c0f-48b9-a867-c806adfabd1f",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-03.mp3?alt=media&token=9b03f820-3ae2-4d77-ba06-9662502670c5",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-10.mp3?alt=media&token=178a69e9-3b58-4a35-b772-af9bbd6836d7",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-4.mp3?alt=media&token=34bb41bb-8c68-41cf-95bd-b1bad7a23d54",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-5.mp3?alt=media&token=39ee1d6a-aed3-4bb4-8b44-6d86f040f534",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-6.mp3?alt=media&token=7fd22095-176c-43df-a625-14975ef4aaa9",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-7.mp3?alt=media&token=08f8dc56-87ad-44d7-8c88-c39f510b1051",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-8.mp3?alt=media&token=26a32a0d-a19c-4bfb-88f9-6fc72235618d",
    "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpage-flip-9.mp3?alt=media&token=fe0f6647-6344-464a-8967-03a35cb5411f"
];

export const pcSound = "https://firebasestorage.googleapis.com/v0/b/formatai.appspot.com/o/soundeffects%2Fpc-boot%20.mp3?alt=media&token=5e66f011-cd0e-4eb4-8529-3dba83744ff2";