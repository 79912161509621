const wittyPhrases = [
    "Warming up AI vocal cords...",
    "Preparing to narrate...",
    "Adding dramatic effect...",
    "Loading text-to-speech...",
    "Making Shakespeare proud...",
    "Preparing the narration...",
    "Loading your voice...",
    "Getting the tone right...",
    "Tuning the speech engine...",
    "Setting the stage...",
    "Fine-tuning the voice...",
    "Prepping the audio...",
    "Crafting the perfect read..."
];

// Function to get a random witty phrase
export const getRandomWittyPhrase = () => {
    const randomIndex = Math.floor(Math.random() * wittyPhrases.length);
    return wittyPhrases[randomIndex];
};