import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './FileUpload.css';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

const FileUpload = ({ onFileUpload, type }) => {
    const maxSize = 50 * 1024 * 1024; // 50MB in bytes

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size > maxSize) {
                alert("File is too large. Maximum size is 10MB.");
                return;
            }
            onFileUpload(file);
        }
    }, [onFileUpload, maxSize]);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        maxSize: maxSize,
        multiple: false
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div key={file.path}>
            {errors.map(e => (
                <p key={e.code}>{e.message}</p>
            ))}
        </div>
    ));

    const renderDefault = () => (
        <div
            style={{ width: '200px', margin: 'auto' }}
            {...getRootProps()}
            className={`file-upload ${isDragActive ? 'active' : ''}`}
        >
            <input {...getInputProps()} />
            <p className='FileUploaderPara'>Drag & drop a PDF file here (max 50MB), or click to select one</p>
            {fileRejectionItems}
        </div>
    );

    const renderOcrScannerUpload = () => (
        <div
            style={{ width: '600px', height: '100%', margin: '90px auto 0px auto' }}
            {...getRootProps()}
            className={`file-upload ${isDragActive ? 'active' : ''}`}
        >
            <input {...getInputProps()} />
            <PdfIcon sx={{ fontSize: '50px' }} />
            <p>You haven't uploaded the source PDF for this book yet.</p>
            <b><p>To get started with your OCR scan:</p></b>
            <p className='FileUploaderPara'>Drag & drop a PDF file here (max 10MB)<br></br> or click to select from your computer.</p>
            {fileRejectionItems}
        </div>
    );

    const renderFileUpload = (uploadType) => {
        if (uploadType === 'ocr') {
            return renderOcrScannerUpload();
        }
        return renderDefault();
    };

    return renderFileUpload(type);
};

export default FileUpload;