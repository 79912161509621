import React, { useState, useEffect, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

export function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}


const useSpeechSynthesis = (highlightCallback, selectedVoice) => {
  const synthRef = useRef(window.speechSynthesis);
  const myTimeoutRef = useRef(null);

  const myTimer = () => {
    synthRef.current.pause();
    synthRef.current.resume();
    myTimeoutRef.current = setTimeout(myTimer, 10000);
  };

  const startSpeech = (text, onEndCallback) => {
    console.log('starting speech')
    console.log(text)
    if (!('speechSynthesis' in window) || !('SpeechSynthesisUtterance' in window)) {
      console.error('Speech Synthesis not supported in this browser.');
      return;
    }

    if (typeof text === 'string' && text.trim()) {
      const utterance = new SpeechSynthesisUtterance(text);
      let currentWordIndex = 0;

      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }

      utterance.pitch = 0;
      utterance.rate = 1;
      utterance.volume = 2;

      let totalWords = 0;
      let hasScrolled = false

      // Count the total words in the text
      const countTotalWords = (text) => {
        return text.split(/\s+/).filter(word => word.trim() !== '').length;
      };

      // Initialize the total word count
      totalWords = countTotalWords(text);

      utterance.onboundary = (event) => {
        if (event.name === 'word') {
          const charIndex = event.charIndex;
          const wordBefore = text.substring(0, charIndex).split(/\s+/).length - 1;
          const currentWord = text.substring(charIndex).split(/\s+/)[0];

          if (currentWord !== '###') {
            highlightCallback(wordBefore);
            currentWordIndex = wordBefore + 1;

            // Scroll when 50% of the words have been read, but only if there are more than 300 words
            if (!hasScrolled && totalWords > 250 && currentWordIndex >= totalWords / 2) {
              scroll.scrollToBottom({
                duration: 2000, // Time in milliseconds for the scroll to complete
                smooth: 'easeInOutQuad', // Easing function
              });
              hasScrolled = true
            }
          }
        }
      };

      utterance.onend = () => {
        highlightCallback(-1); // Clear highlighting when done
        clearTimeout(myTimeoutRef.current);
        if (onEndCallback) {
          console.log("speech is done")
          onEndCallback(); // Notify when speech is done
          stopSpeech();
        }
      };

      synthRef.current.speak(utterance);
      myTimeoutRef.current = setTimeout(myTimer, 100);
    }
  };

  const pauseSpeech = () => {
    if (synthRef.current.speaking && !synthRef.current.paused) {
      synthRef.current.pause();
      clearTimeout(myTimeoutRef.current);
    }
  };

  const resumeSpeech = () => {
    console.log("resuming")
    synthRef.current.resume();
    myTimeoutRef.current = setTimeout(myTimer, 100);
  };

  // Cleanup function to stop speech when component unmounts or text changes
  useEffect(() => {
    return () => {
      synthRef.current.cancel();
      clearTimeout(myTimeoutRef.current);
      highlightCallback(-1); // Clear highlighting on cleanup
    };
  }, [highlightCallback, selectedVoice]);

  const stopSpeech = () => {
    synthRef.current.cancel();
    clearTimeout(myTimeoutRef.current);
    highlightCallback(-1); // Clear highlighting when stopped
  };

  return { startSpeech, pauseSpeech, resumeSpeech, stopSpeech };
};

export default useSpeechSynthesis;