import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // Importing the ellipsis icon

const actions = [
    { icon: <DeleteIcon sx={{ color: '#fff' }} />, name: 'Delete Source PDF', color: '#fff' }, // Set icon color to red
];

const PDFSpeedDial = ({ onActionClick }) => {
    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{
                position: 'fixed',
                bottom: 20,
                left: 20,
            }}
            FabProps={{
                sx: {
                    color: 'white',
                    bgcolor: 'transparent',
                    '&:hover': {
                        bgcolor: 'transparent',
                    }
                }
            }}
            icon={<SpeedDialIcon icon={<MoreHorizIcon />} openIcon={<MoreHorizIcon />} />} // Change icon to ellipsis
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => onActionClick(action.name)}
                    FabProps={{
                        sx: {
                            bgcolor: 'red',
                            '&:hover': {
                                bgcolor: 'red',
                            }
                        }
                    }}
                    sx={{
                        backgroundColor: action.color, // Set background color
                        color: 'warning', // Set icon color to white for contrast
                        '&:hover': {
                            backgroundColor: action.color, // Keep color on hover
                        },
                    }}
                />
            ))}
        </SpeedDial>
    );
};

export default PDFSpeedDial;
