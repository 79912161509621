import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './CoverUpload.css';

const CoverUpload = ({ onFileUpload, bookTitle }) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size > maxSize) {
                alert("File is too large. Maximum size is 5MB.");
                return;
            }
            onFileUpload(file);
        }
    }, [onFileUpload, maxSize]);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
        maxSize: maxSize,
        multiple: false
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div key={file.path}>
            {errors.map(e => (
                <p key={e.code}>{e.message}</p>
            ))}
        </div>
    ));

    return (
        <div style={{ width: '300px', height: '375px', margin: 'auto' }} {...getRootProps()} className={`file-upload ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            <p><b>{bookTitle}</b></p>
            <br />
            <p><i>To set a book cover, <br />drop a <b>.jpg</b> or <b>.png</b> file here (max 5MB)<br></br><br></br>Alternatively, click to select a file from your computer.</i></p>
            {fileRejectionItems}
        </div>
    );
};

export default CoverUpload;